import React from 'react'
import styled from 'styled-components'

const Logo = styled.svg`
  width: ${props => props.smallLogo ?
    "80px" : "183px"};
  height: ${props => props.smallLogo ?
    "43px" : "130px"};

  .cls-1, .cls-3 {
        fill: #fff;
      }

      .cls-1, .cls-2 {
        fill-rule: evenodd;
      }

      .cls-2, .cls-5 {
        fill: #e13338;
      }

      .cls-4 {
        fill: #231f20;
      }
`
const HammerNutritionLogo = props => {
  return (
    <div>
      <a href="http://www.hammernutrition.com/" target="_blank" rel="noopener noreferrer">

<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 148.73878 81.70426" {...props}>
  <title>hammer-nutrition-logo</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls-1" d="M124.019,41.20817h0Z"/>
      <path class="cls-2" d="M143.7769,52.27066a1.72207,1.72207,0,0,1-1.833,1.58791L77.9058,62.549,7.3521,53.52647a1.71942,1.71942,0,0,1-1.8213-1.5957l.0957-38.7734a1.72108,1.72108,0,0,1,1.83-1.5879l69.2803-6.6797,65.3125,7.0117a1.72558,1.72558,0,0,1,1.8233,1.5977Z"/>
      <g>
        <path class="cls-3" d="M42.83641,43.66517l3.27839.0078.3994,6.9336h.03021l1.49129-6.9297,2.7431.0059-2.4209,11.247L45.144,54.922l-.458-6.9521h-.0322l-1.4941,6.9463-2.74029-.0078Z"/>
        <path class="cls-3" d="M55.75641,43.69447l-1.74221,8.1113c-.2373,1.08789.0351,1.3731.584,1.3731.5537.0019.94621-.2793,1.1806-1.36721l1.7442-8.11129,2.9335.0078-1.5878,7.3711c-.6758,3.14939-2.23729,4.0908-4.6944,4.0849-2.459-.0058-3.6094-.9551-2.9336-4.10451l1.586-7.37109Z"/>
        <path class="cls-3" d="M63.4487,46.20617l-2.208-.0078.5371-2.4883,7.3409.01959-.5342,2.48821-2.20309-.0078-1.88381,8.7588-2.9317-.0078Z"/>
        <path class="cls-3" d="M70.5708,43.73357l4.5859.0117c2.2344.0039,2.8174,1.2656,2.4444,2.998a2.99313,2.99313,0,0,1-2.4981,2.6739l-.0058.0312c1.3886.1289,1.622,1.02929,1.3017,2.5234l-.209.961a4.55764,4.55764,0,0,0-.1923,1.5595.56916.56916,0,0,0,.25781.3497l-.03621.1543-3.121-.0059a5.85886,5.85886,0,0,1,.1777-2.1416l.164-.7559c.27151-1.2773.0928-1.6093-.6171-1.6093l-.7735-.002-.9678,4.5049-2.9306-.00781Zm1.9102,4.7617.58.0019a1.49938,1.49938,0,0,0,1.5166-1.3359c.1993-.9141-.08-1.30661-.9628-1.30861l-.56739-.00389Z"/>
        <path class="cls-3" d="M80.29441,43.755l2.92969.0098L80.8062,55.008l-2.9327-.0059Z"/>
        <path class="cls-3" d="M86.2358,46.26287l-2.2089-.0079.5371-2.4882,7.3427.0195-.5341,2.4863-2.2071-.00391-1.8838,8.75691-2.9296-.00791Z"/>
        <path class="cls-3" d="M93.2485,43.78817l2.9288.0079-2.419,11.2451-2.9307-.0078Z"/>
        <path class="cls-3" d="M101.9751,43.591c2.9941.0058,3.6689,1.6465,2.7637,5.8515-.9063,4.2051-2.2832,5.8409-5.27739,5.835-2.99311-.00981-3.666-1.6475-2.75971-5.8525C97.6069,45.216,98.9819,43.58317,101.9751,43.591Zm-2.0879,9.7012c.8985.0019,1.1719-.81651,1.8233-3.85751.6533-3.039.7343-3.85929-.1612-3.86129-.8974-.002-1.16789.8164-1.8222,3.8574C99.0708,52.46987,98.9898,53.28817,99.8872,53.29217Z"/>
        <path class="cls-3" d="M107.7896,43.82337l3.2783.0098.3994,6.9336.0342.00189,1.4883-6.93159,2.7421.0078L113.312,55.09l-3.2129-.0098-.459-6.9482h-.0341l-1.4942,6.9443-2.7392-.0058Z"/>
      </g>
      <g>
        <path class="cls-3" d="M14.8218,15.46207l6.331.01559-1.917,8.91211,3.8799.0098,1.919-8.9141,6.331.0176-5.2256,24.291-6.333-.0156,2.1534-10.002-3.879-.00781-2.1542,10-6.3301-.01559Z"/>
        <path class="cls-3" d="M38.6851,15.51866l7.9638.02151.6983,24.3047-6.7403-.0156.2666-4.2539-5.03709-.01371L34.2769,39.81357,27.5347,39.8Zm2.94631,4.97851H41.5649l-3.9541,10.3047,3.5411.00979Z"/>
        <path class="cls-3" d="M53.729,15.55777l9.0567.0215-.542,14.39839h.0664l5.6474-14.38279,9.0547.0215-5.2265,24.289-5.9209-.0136,3.8955-18.0996h-.0704l-7.2626,18.0917-4.5625-.0097.5253-18.1094h-.0712l-3.8916,18.0996-5.92189-.0156Z"/>
        <path class="cls-3" d="M79.8735,15.62217l9.0538.02149-.54589,14.39851h.06829l5.6504-14.3848,9.0557.0215-5.2276,24.2929-5.92179-.0156,3.89639-18.0996h-.0703l-7.2646,18.0918-4.56149-.01171.52439-18.10739h-.06639L80.5689,39.92887l-5.9209-.0157Z"/>
        <path class="cls-3" d="M106.25541,15.68857l14.39649.0352-1.1123,5.16989-8.0674-.01949-.8936,4.1523,7.5567.0196-1.0674,4.9668-7.5596-.0215-1.0391,4.83,8.3741.0215-1.1104,5.17-14.706-.03321Z"/>
        <path class="cls-3" d="M123.6118,15.73157l9.9072.0234c4.8331.0118,6.085,2.7383,5.2833,6.4805-.6954,3.2324-2.4112,5.3379-5.3995,5.7715l-.0156.0703c3.0078.2793,3.5068,2.2227,2.8125,5.4531l-.44729,2.0762a9.98074,9.98074,0,0,0-.41691,3.3652,1.21515,1.21515,0,0,0,.5546.752l-.0752.3418-6.7402-.0176a12.579,12.579,0,0,1,.3828-4.6269l.3526-1.6368c.5927-2.7539.2002-3.4687-1.3282-3.47261l-1.6679-.00389-2.0957,9.7285-6.3282-.01561Zm4.124,10.28909,1.2588.00191a3.23977,3.23977,0,0,0,3.2754-2.8847c.4239-1.9727-.1758-2.82621-2.0801-2.82821l-1.22549-.00389Z"/>
      </g>
      <g>
        <path class="cls-4" d="M0,72.96786H4.4282v.9366H1.0562V76.592H4.2363v.9365H1.0562v3.0722H4.5723v.9356H0Z"/>
        <path class="cls-4" d="M6.7559,72.96786H8.124l2.8321,7.1524H10.98v-7.1524h1.0561v8.5684H10.584l-2.7481-6.9365H7.812v6.9365H6.7559Z"/>
        <path class="cls-4" d="M14.7363,72.96786h2.292c2.66411,0,3,1.92,3,4.2842,0,2.1602-.54,4.2842-2.8921,4.2842H14.7363ZM15.792,80.672h1.104c1.8242,0,2.0283-1.872,2.0283-3.4082,0-1.6552-.10789-3.4316-2.0283-3.4316H15.792Z"/>
        <path class="cls-4" d="M22.4043,72.96786H23.46v6.0244c0,1.2598.4082,1.8477,1.4521,1.8477,1.1279,0,1.4639-.6475,1.4639-1.8477v-6.0244h1.0561v6.0244c0,1.6436-.8398,2.712-2.52,2.712-1.7158,0-2.5078-.9483-2.5078-2.712Z"/>
        <path class="cls-4" d="M29.9277,72.96786h2.7725a2.25576,2.25576,0,0,1,.876,4.3086V77.3a1.40967,1.40967,0,0,1,1.2119,1.4287l.0718,1.4638c.0483.9473.2041,1.1875.456,1.3438H34.044A2.60233,2.60233,0,0,1,33.7559,80.3l-.0718-1.3321c-.084-1.1757-.7319-1.248-1.272-1.248H30.9839v3.8164H29.9277Zm1.0562,3.8887h1.0439c.6241,0,1.7642,0,1.7642-1.6562,0-.8399-.564-1.3682-1.5479-1.3682H30.9839Z"/>
        <path class="cls-4" d="M39.0361,72.96786h1.4161l2.30421,8.5684H41.6523l-.6123-2.3516H38.3042l-.6362,2.3516H36.564Zm-.5161,5.3526h2.3042l-1.09179-4.1407H39.708Z"/>
        <path class="cls-4" d="M44.5557,72.96786h1.3681l2.8321,7.1524h.0239v-7.1524h1.0561v8.5684H48.3838l-2.7481-6.9365h-.0239v6.9365H44.5557Z"/>
        <path class="cls-4" d="M57.6719,78.59586c-.0957,1.6201-.732,3.1084-2.604,3.1084-2.4361,0-2.7598-2.292-2.7598-4.4522S52.7041,72.8,55.0679,72.8c1.7519,0,2.4839.9843,2.5322,2.664h-1.104c-.0239-.9717-.3359-1.7998-1.4282-1.7998-1.4639,0-1.6558,1.6084-1.6558,3.5879,0,1.9805.1919,3.5879,1.6558,3.5879,1.2842,0,1.4638-1.2002,1.5-2.2441Z"/>
        <path class="cls-4" d="M59.856,72.96786h4.4282v.9366H60.9121V76.592h3.1802v.9365H60.9121v3.0722h3.5161v.9356H59.856Z"/>
        <path class="cls-4" d="M74.3281,75.18856c0-.9004-.3242-1.5244-1.332-1.5244a1.27117,1.27117,0,0,0-1.416,1.3916c0,2.4121,4.0434.9727,4.0434,4.1045a2.40588,2.40588,0,0,1-2.6635,2.544c-2.0039,0-2.5918-1.0323-2.5918-2.8565h1.0561c0,.961.0596,1.9922,1.6079,1.9922a1.44273,1.44273,0,0,0,1.4878-1.5117c0-2.4238-4.0439-1.0205-4.0439-4.1641A2.24019,2.24019,0,0,1,72.9839,72.8a2.12149,2.12149,0,0,1,2.4004,2.3886Z"/>
        <path class="cls-4" d="M77.79541,72.96786H78.8521v6.0244c0,1.2598.4082,1.8477,1.4511,1.8477,1.1289,0,1.4649-.6475,1.4649-1.8477v-6.0244h1.0556v6.0244c0,1.6436-.8398,2.712-2.5205,2.712-1.71479,0-2.50779-.9483-2.50779-2.712Z"/>
        <path class="cls-4" d="M85.3198,72.96786h2.18461c.74409,0,2.65229,0,2.65229,2.46a2.3034,2.3034,0,0,1-2.4726,2.4844H86.3755v3.624H85.3198Zm1.0557,4.0801h1.2607c.7676,0,1.4161-.5273,1.4161-1.668a1.35957,1.35957,0,0,0-1.4883-1.5478H86.3755Z"/>
        <path class="cls-4" d="M92.2915,72.96786h2.1836c.7441,0,2.65231,0,2.65231,2.46a2.30265,2.30265,0,0,1-2.47161,2.4844H93.3482v3.624H92.2915Zm1.0567,4.0801h1.2597c.7676,0,1.416-.5273,1.416-1.668a1.3595,1.3595,0,0,0-1.4882-1.5478H93.3482Z"/>
        <path class="cls-4" d="M99.2632,72.96786h1.0557v7.6328h3.4804v.9356H99.2632Z"/>
        <path class="cls-4" d="M105.7915,72.96786h4.4277v.9366h-3.372V76.592h3.1806v.9365h-3.1806v3.0722h3.5166v.9356h-4.5723Z"/>
        <path class="cls-4" d="M112.5718,72.96786h1.7764l1.7148,6.792h.02441l1.71579-6.792h1.7764v8.5684h-1.0557V74.048h-.0244l-1.8955,7.4883h-1.05659L113.6509,74.048h-.02349v7.4883H112.5718Z"/>
        <path class="cls-4" d="M122.1958,72.96786h4.4277v.9366h-3.372V76.592h3.1806v.9365h-3.1806v3.0722h3.5166v.9356h-4.5723Z"/>
        <path class="cls-4" d="M128.9517,72.96786h1.3672l2.832,7.1524h.0244v-7.1524h1.0557v8.5684h-1.4512l-2.7481-6.9365h-.0244v6.9365h-1.0556Z"/>
        <path class="cls-4" d="M139.4273,81.53626h-1.0557v-7.6318h-2.1484v-.9366h5.36421v.9366H139.4273Z"/>
        <path class="cls-4" d="M147.2153,75.18856c0-.9004-.3242-1.5244-1.332-1.5244a1.271,1.271,0,0,0-1.416,1.3916c0,2.4121,4.0449.9727,4.0449,4.1045a2.40668,2.40668,0,0,1-2.664,2.544c-2.0049,0-2.59279-1.0323-2.59279-2.8565H144.311c0,.961.0606,1.9922,1.6075,1.9922a1.442,1.442,0,0,0,1.4882-1.5117c0-2.4238-4.0429-1.0205-4.0429-4.1641A2.24019,2.24019,0,0,1,145.8716,72.8a2.12249,2.12249,0,0,1,2.4004,2.3886Z"/>
      </g>
      <path class="cls-3" d="M117.54835,44.88586a2.0529,2.0529,0,0,1,.4375-.64844,2.02507,2.02507,0,0,1,.64942-.4375,2.05047,2.05047,0,0,1,1.58886,0,2.0485,2.0485,0,0,1,1.08692,1.08594,2.06444,2.06444,0,0,1,0,1.58984,2.04454,2.04454,0,0,1-.4375.64649,2.00693,2.00693,0,0,1-.64942.42871,2.13484,2.13484,0,0,1-1.58886,0,1.98617,1.98617,0,0,1-.64942-.42871,2.04438,2.04438,0,0,1-.4375-.64649,2.06444,2.06444,0,0,1,0-1.58984Zm.56348,1.3584a1.45128,1.45128,0,0,0,.29687.46484,1.41743,1.41743,0,0,0,.45117.31348,1.46414,1.46414,0,0,0,1.13868,0,1.402,1.402,0,0,0,.748-.77832,1.534,1.534,0,0,0,0-1.12793,1.43966,1.43966,0,0,0-.29687-.46485,1.41483,1.41483,0,0,0-.45117-.3125,1.46414,1.46414,0,0,0-1.13868,0,1.41494,1.41494,0,0,0-.45117.3125,1.47306,1.47306,0,0,0-.29687.46485,1.53414,1.53414,0,0,0,0,1.12793Zm1.3789-1.66309a.97164.97164,0,0,1,.63672.17578.62212.62212,0,0,1,.209.50586.57206.57206,0,0,1-.15625.43164.65171.65171,0,0,1-.3877.17871l.54395.90723h-.52735l-.51757-.88477h-.17579v.88477h-.48437V44.58117Zm-.00586.92969a.616.616,0,0,0,.26368-.05078c.06933-.03223.10449-.10742.10449-.22461a.24641.24641,0,0,0-.03809-.14649.26292.26292,0,0,0-.10156-.0791.49132.49132,0,0,0-.13867-.03613,1.16913,1.16913,0,0,0-.13965-.00781h-.31934v.54492Z"/>
      <path class="cls-5" d="M77.8589,67.48449a4.77341,4.77341,0,0,1-.625-.04L6.9048,58.45129a6.62122,6.62122,0,0,1-6.333-6.49121c0-.00586.09521-38.79248.09521-38.79248A6.62866,6.62866,0,0,1,7.09913,6.68566L76.2173.02355a4.90549,4.90549,0,0,1,.998.00391l65.127,6.9917a6.90113,6.90113,0,0,1,4.52246,1.99268,6.34869,6.34869,0,0,1,1.874,4.501c0,.00342-.02832,11.76221-.05273,22.333l-.04,16.4707a6.63218,6.63218,0,0,1-6.32032,6.46582L78.52,67.44055A5.01288,5.01288,0,0,1,77.8589,67.48449ZM7.29689,57.30383l70.08935,8.96289a3.73557,3.73557,0,0,0,.97363-.00391l63.54688-8.62207v-.0332a3.94086,3.94086,0,0,0,.49121-.03418h.001l.00195.01269a5.4353,5.4353,0,0,0,5.05762-5.27343h0s.01855-7.83887.03906-16.46973l.05371-22.331A5.17,5.17,0,0,0,146.023,9.84973,5.70192,5.70192,0,0,0,142.40382,8.213l-.001.00781h-.001c-.12989-.01416-.2627-.02051-.39356-.02246v-.02L77.08839,1.2091a3.65391,3.65391,0,0,0-.752-.00391l-.00293.00049L7.41017,7.84924v.01758c-.10449.00244-.21924.00439-.32813.01416l-.02734.00244-.00049-.00586a5.43337,5.43337,0,0,0-5.19873,5.293l-.0957,38.79052A5.42554,5.42554,0,0,0,6.834,57.256l.00146-.01075.02832.00293a3.52584,3.52584,0,0,0,.37793.02637c.01856.001.03711.001.05566.001Z"/>
    </g>
  </g>
</Logo>
</a>
</div>
  )
    }

export default HammerNutritionLogo
