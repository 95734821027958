import React from 'react'
import styled from 'styled-components'

const Logo = styled.svg`
  width: ${props => props.smallLogo ?
    "80px" : "183px"};
  height: ${props => props.smallLogo ?
    "25px" : "130px"};
  
  .cls-1 {
        fill: #da1a35;
      }

      .cls-2 {
        fill: #919195;
      }
`
const SwixLogo = props => {
  return (
    <div>
      <a href="https://tvtap.org" target="_blank" rel="noopener noreferrer">
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 474.40962 148.66357" {...props}>

  <title>swix-logo</title>
  <g id="Layer_2" data-name="Layer 2">
    <g>
      <path class="cls-1" d="M159.02133,44.9961a2.9013,2.9013,0,0,0-.6396-2.0635,2.59578,2.59578,0,0,0-1-.6817,5.74534,5.74534,0,0,0-2.335-.2685l-93.4268.001a31.06164,31.06164,0,0,0-10.3671,2.416A29.50531,29.50531,0,0,0,40.452,52.3369a30.78847,30.78847,0,0,0-4.8575,8.2969,27.45507,27.45507,0,0,0-1.8613,9.6318,26.81981,26.81981,0,0,0,1.125,8.1343,30.08428,30.08428,0,0,0,4.1377,8.5781,30.87915,30.87915,0,0,0,5.4619,5.8301,39.42524,39.42524,0,0,0,6.7168,4.0019c2.3467,1.125,51.8213,19.2969,52.4063,19.6739a3.50778,3.50778,0,0,1,1.6279,1.9902,3.39774,3.39774,0,0,1-.5928,3.0039,3.28844,3.28844,0,0,1-2.8945,1.1719s-.1221.0117-.3086.0117l-89.5508.0039h-.2959a3.34232,3.34232,0,0,0-1.4189.3848,3.592,3.592,0,0,0-1.3858,1.4082S.96953,142.144.26063,144.0053a3.31707,3.31707,0,0,0,.999,3.9161,3.68591,3.68591,0,0,0,2.1543.7421h101.123a39.66022,39.66022,0,0,0,10.4258-1.7656,28.61466,28.61466,0,0,0,12.7637-8.47071,27.32941,27.32941,0,0,0,6.1943-12.19919,27.77948,27.77948,0,0,0,.4102-10.4707,27.4764,27.4764,0,0,0-3.2842-9.582c-4.2549-7.2696-8.6113-9.9317-13.209-12.1329-4.958-2.371-53.1435-19.1875-54.4394-19.7993a3.35975,3.35975,0,0,1-1.8584-3.9492,3.416,3.416,0,0,1,1.5947-1.9912,3.11178,3.11178,0,0,1,1.668-.3936c.7168-.0302,82.4521.0118,82.4521.0118a3.33111,3.33111,0,0,0,1.5596-.39071,2.95967,2.95967,0,0,0,1.1699-1.10349c1.6582-2.56051,8.5723-19.6318,8.5723-19.6318A7.71647,7.71647,0,0,0,159.02133,44.9961Z"/>
      <path class="cls-1" d="M270.69423,43.74219a4.48848,4.48848,0,0,1,.9658-1.18359,3.21761,3.21761,0,0,1,1.9355-.5938h20.3721a3.1999,3.1999,0,0,1,2.75,1.3203,3.29461,3.29461,0,0,1,.4189,3.16509l-26.5166,62.063c-8.0478,18.59761-20.3623,40.55661-39.9755,40.14251h-17.5528a4.16514,4.16514,0,0,1-2.2392-.7617c-4.0342-2.9375-4.9327-4.2402-7.5899-8.2383a3.556,3.556,0,0,0-5.4346-.4511c-4.5976,4.0488-12.3964,9.33-18.4804,9.4589H158.284a3.40264,3.40264,0,0,1-1.9355-.5586c-8.1622-6.1972-18.8614-23.2968-11.4756-42.6972,0,0,25.9218-61.2202,26.0722-61.5357a3.41634,3.41634,0,0,1,1.1114-1.2871,3.32749,3.32749,0,0,1,1.792-.6201h21.7021a3.31742,3.31742,0,0,1,2.6416,1.3242,3.2263,3.2263,0,0,1,.5283,3.1241l-24.4463,55.0473c-7.3916,16.6231,1.0098,19.6465,1.5713,19.7949.7422.0528,10.3731.13089,20.3799-20.8964,0,0,24.5088-56.2232,24.5986-56.3765a4.216,4.216,0,0,1,1.128-1.3789,3.19579,3.19579,0,0,1,1.9248-.6387h20.999a2.99176,2.99176,0,0,1,2.8203,1.6748,3.45081,3.45081,0,0,1,.209,2.7285c-.2715.8545-24.6885,56.5122-24.6885,56.5122-6.1982,13.7657,2.918,18.5157,3.3545,18.5743.3897-.02741,10.0615-1.8711,16.2539-14.8887C242.825,106.5659,270.58583,43.9258,270.69423,43.74219Z"/>
      <path class="cls-1" d="M438.63462,41.9775s20.9795-.0019,32.5058.0059a3.38259,3.38259,0,0,1,3.12411,2.4336,3.30212,3.30212,0,0,1-.95611,3.3994c-.41889.3515-53.8965,42.5444-63.6191,50.5483-.9239.8242-1.793,2.6836-.1543,5.041,6.3584,9.1524,23.0556,39.4864,23.2451,39.9004a3.68438,3.68438,0,0,1-3.6934,5.3477l-23.4589.0097a3.03749,3.03749,0,0,1-2.1377-1.0605,4.99276,4.99276,0,0,1-.6495-.92581c-.2763-.50189-11.6709-20.63869-14.9052-26.45509a3.4915,3.4915,0,0,0-3.003-1.8027,3.83249,3.83249,0,0,0-2.41989.9863c-.29391.22849-36.25781,28.2246-36.64161,28.52349a3.3149,3.3149,0,0,1-2.2969.73431s-32.27139-.0019-32.88569,0a3.2416,3.2416,0,0,1-3.0527-2.1054,3.36882,3.36882,0,0,1,.9902-3.6973c.4189-.3691,61.43649-47.8574,61.90719-48.2461a3.38857,3.38857,0,0,0,.7002-4.3242c-.4941-.8262-24.4658-43.4106-24.4658-43.4106a3.41389,3.41389,0,0,1,.1543-3.5186,3.54385,3.54385,0,0,1,2.75-1.3965h23.2656a3.02269,3.02269,0,0,1,2.5117,1.7256c.2207.4199,16.2246,28.7559,16.4356,29.1113a3.48435,3.48435,0,0,0,2.8271,1.6826,4.213,4.213,0,0,0,2.3194-.7666c.4258-.3544,39.1455-30.4336,39.4638-30.6523A3.8132,3.8132,0,0,1,438.63462,41.9775Z"/>
      <path class="cls-1" d="M438.31532,142.1948a6.5223,6.5223,0,1,1,6.54,6.4551A6.428,6.428,0,0,1,438.31532,142.1948Zm6.54,5.3809a5.3657,5.3657,0,1,0-5.2432-5.3809A5.1962,5.1962,0,0,0,444.85532,147.5757Zm-1.3633-1.6504h-1.1211v-7.4258h2.8223c1.7558,0,2.6269.6465,2.6269,2.1113a1.92836,1.92836,0,0,1-1.9248,2.043l2.1123,3.2715h-1.2627l-1.958-3.2207H443.492Zm1.3457-4.1739c.9531,0,1.8047-.0683,1.8047-1.209,0-.9179-.833-1.0898-1.6182-1.0898H443.492v2.2988Z"/>
      <path class="cls-1" d="M338.17762,41.9648h-21.2627a3.31049,3.31049,0,0,0-1.7598.4512,3.66,3.66,0,0,0-1.0723,1.0986,19.29436,19.29436,0,0,0-.9189,2.0127l-39.98929,94.8765s-1.459,3.0176-1.7119,3.94139a3.5989,3.5989,0,0,0,.1543,2.47071,3.306,3.306,0,0,0,2.8858,1.8476H296.036a5.52167,5.52167,0,0,0,1.4521-.36131,3.54482,3.54482,0,0,0,1.5303-1.69529s42.09669-99.7183,42.19039-100.0122a4.08381,4.08381,0,0,0,.166-1.9463,3.82836,3.82836,0,0,0-.5351-1.40721A3.02383,3.02383,0,0,0,338.17762,41.9648Z"/>
      <path class="cls-2" d="M353.84853,17.2841A17.28666,17.28666,0,1,1,336.56432,0,17.28707,17.28707,0,0,1,353.84853,17.2841Z"/>
    </g>
  </g>
</Logo>
</a>
</div>
  )
    }
    export default SwixLogo
