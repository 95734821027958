import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from 'react-bootstrap'
import MadshusLogo from "../../images/madshus-logo"
import Streubel from '../../images/streubel-physical-therapy'
import TVTAPLogo from '../../images/trails-and-pathways-logo'

const Partners = () => {
    const data = useStaticQuery(
        graphql`
  query {
    tvsef: file(relativePath: { eq: "tvsef-logo.jpg" }) {
      childImageSharp {
        fixed (width: 175, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
    )
    return (
    <Container>
        <Row className="my-4">
            <Col>
            <h3 className="title text-center">
            Partners
        </h3>
            </Col>
        </Row>
        
        <Row className="justify-content-sm-center align-items-center">
        <Col className="text-center my-4" sm={3}>
        <a href="https://madshus.com/" target="_blank" rel="noopener noreferrer">
          <MadshusLogo />
        </a>
        </Col>
        <Col className="text-center my-4" sm={3}>
            <Streubel />
        </Col>
        <Col className="text-center my-4" sm={3}>
            <a href="http://tvsef.org/" target="_blank" rel="noopener noreferer">
            <Img
                fixed={data.tvsef.childImageSharp.fixed}
                alt="Teton Ski Education Foundation"
            />
            </a>
        </Col>
        <Col className="text-center my-4" sm={3}>
            <TVTAPLogo />
        </Col>
        </Row>
    </Container>
    )
}

export default Partners