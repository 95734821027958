
import React from 'react'
import styled from 'styled-components'

const Logo = styled.svg`
  width: ${props => props.smallLogo ?
    "175px" : "250px"};
  height: ${props => props.smallLogo ?
    "50px" : "62px"};
    
  .cls-1 {
    fill: #27347c;
  }
  .cls-2 {
    fill: #fff;
  }

`
const Streubel = props => {
  return (
    <div>
    <a href="http://www.streubelphysicaltherapy.com/" target="_blank" rel="noopener noreferrer" >
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 307.96489 76.14653" {...props}>
  <title>streubel-physical-therapy</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Streubel">
      <g>
        <path class="cls-1" d="M20.02687,20.32129C13.47658,17.78516,11.374,15.78418,11.374,12.083c0-3.11719,2.47461-6.2627,8.00342-6.2627a17.39912,17.39912,0,0,1,8.33105,2.08106l.52246.28515,1.92969-5.709-.35937-.2041A20.71029,20.71029,0,0,0,19.57814,0C10.69,0,4.482,5.24414,4.482,12.75293c0,6.083,3.89258,10.2959,12.23828,13.25488,5.96533,2.294,8.18311,4.59961,8.18311,8.50489,0,4.4414-3.42578,7.20019-8.94043,7.20019A20.24206,20.24206,0,0,1,5.7798,38.84082L5.22658,38.5,3.459,44.36523l.30811.20508c2.57568,1.71778,7.53564,2.96387,11.79394,2.96387,12.03223,0,16.30127-7.26758,16.30127-13.49023C31.86232,27.627,28.32277,23.52344,20.02687,20.32129Z"/>
        <polygon class="cls-1" points="37.019 6.69 50.744 6.69 50.744 46.864 57.637 46.864 57.637 6.69 71.429 6.69 71.429 0.736 37.019 0.736 37.019 6.69"/>
        <path class="cls-1" d="M105.69435,33.86621c-1.06885-4.67676-3.03808-7.65918-6.00342-9.07812a12.20984,12.20984,0,0,0,7.89063-11.63379,11.66444,11.66444,0,0,0-3.71289-8.94141C100.95412,1.57715,96.82375.40137,90.47658.40137a61.107,61.107,0,0,0-11.28174.94726l-.3999.082v45.4336h6.82519V27.31348h4.97168c5.0376.19043,7.28028,2.38183,8.56983,8.38086,1.48242,6.55175,2.37109,9.71582,3.06836,10.91992l.144.25h7.15137l-.39649-.73731C108.20314,44.40723,107.07424,39.76074,105.69435,33.86621Zm-4.9375-20.042c0,4.90234-3.79883,8.07031-9.67773,8.07031h-5.459V6.31348a26.26771,26.26771,0,0,1,5.25293-.42578C95.38185,5.93652,100.75685,7.35254,100.75685,13.82422Z"/>
        <polygon class="cls-1" points="124.986 25.706 142.528 25.706 142.528 19.886 124.986 19.886 124.986 6.624 143.533 6.624 143.533 0.736 118.161 0.736 118.161 46.864 144.537 46.864 144.537 40.977 124.986 40.977 124.986 25.706"/>
        <path class="cls-1" d="M181.76466,27.95117c0,9.09082-3.624,13.89551-10.48046,13.89551-6.45606,0-10.01172-4.93457-10.01172-13.89551V.73633H154.3799V27.81738c0,12.75782,5.93164,19.78321,16.70312,19.78321,6.56348,0,17.57422-2.6045,17.57422-20.05079V.73633h-6.89258Z"/>
        <path class="cls-1" d="M222.06349,22.06152c4.24317-1.96484,6.8291-5.67285,6.8291-9.91113A9.9548,9.9548,0,0,0,224.95705,3.794C222.06056,1.44727,218.14357.40137,212.25588.40137a57.12583,57.12583,0,0,0-10.749.94824l-.39648.084V46.666l.43164.05957a68.0922,68.0922,0,0,0,9.10742.54c7.52539,0,12.73828-1.42285,15.94922-4.35937a12.653,12.653,0,0,0,3.96777-9.39746C230.56642,27.18945,226.21974,23.50586,222.06349,22.06152Zm-9.33886-2.24316h-4.78907V6.20117a27.80817,27.80817,0,0,1,4.65528-.31347C218.834,5.8877,222,8.19727,222,12.75293,222,17.04492,218.35939,19.81836,212.72463,19.81836Zm-.40137,21.89453a35.20125,35.20125,0,0,1-4.3877-.19629V25.2373h4.32032c3.35253,0,11.15039.79981,11.15039,8.20411C223.40627,40.63867,216.46193,41.71289,212.32326,41.71289Z"/>
        <polygon class="cls-1" points="247.568 25.706 265.11 25.706 265.11 19.886 247.568 19.886 247.568 6.624 266.115 6.624 266.115 0.736 240.743 0.736 240.743 46.864 267.119 46.864 267.119 40.977 247.568 40.977 247.568 25.706"/>
        <polygon class="cls-1" points="283.855 40.977 283.855 0.736 277.029 0.736 277.029 46.864 303.138 46.864 303.138 40.977 283.855 40.977"/>
      </g>
    </g>
    <g id="Box">
      <rect class="cls-1" y="49.59763" width="307.96489" height="26.5489"/>
    </g>
    <g id="Text">
      <g>
        <path class="cls-2" d="M3.0259,68.09375V56.44238h4.395a10.6,10.6,0,0,1,1.772.11133,3.51492,3.51492,0,0,1,1.43847.54492,2.86123,2.86123,0,0,1,.93409,1.124,3.79364,3.79364,0,0,1-.59229,4.10546,4.53864,4.53864,0,0,1-3.41748,1.0293H4.5674v4.73633Zm1.5415-6.11133H7.57961a3.19316,3.19316,0,0,0,2.12207-.55664,1.98253,1.98253,0,0,0,.62793-1.56543A2.10478,2.10478,0,0,0,9.96,58.6084a1.72826,1.72826,0,0,0-.97364-.6875,6.61946,6.61946,0,0,0-1.43847-.10352H4.5674Z"/>
        <path class="cls-2" d="M14.74367,68.09375V56.44238h1.542v4.78418h6.05615V56.44238h1.542V68.09375h-1.542V62.60156H16.28566v5.49219Z"/>
        <path class="cls-2" d="M30.5464,68.09375V63.1582l-4.49023-6.71582h1.87549l2.29687,3.5127q.63575.98583,1.18408,1.97168.52441-.91407,1.27149-2.0586l2.25732-3.42578h1.7959L32.0884,63.1582v4.93555Z"/>
        <path class="cls-2" d="M38.40968,64.35059l1.45459-.127a3.20927,3.20927,0,0,0,.481,1.43457,2.62369,2.62369,0,0,0,1.17236.90528,4.43784,4.43784,0,0,0,1.78809.3457,4.28567,4.28567,0,0,0,1.55762-.26172,2.161,2.161,0,0,0,1.00537-.71973,1.66849,1.66849,0,0,0,.33008-.99707,1.5195,1.5195,0,0,0-.31836-.958,2.35883,2.35883,0,0,0-1.04883-.6875,20.93813,20.93813,0,0,0-2.07422-.56836,10.62065,10.62065,0,0,1-2.24951-.72657,3.16115,3.16115,0,0,1-1.24365-1.085,2.65667,2.65667,0,0,1-.40918-1.45019,2.9635,2.9635,0,0,1,.50049-1.64942,3.09674,3.09674,0,0,1,1.4624-1.16406,5.53619,5.53619,0,0,1,2.13818-.39746,5.83774,5.83774,0,0,1,2.28467.417,3.298,3.298,0,0,1,1.522,1.22754,3.52729,3.52729,0,0,1,.57227,1.83594l-1.478.11133a2.38852,2.38852,0,0,0-.80664-1.669,3.14185,3.14185,0,0,0-2.03076-.56445,3.25775,3.25775,0,0,0-2.03858.51269,1.53714,1.53714,0,0,0-.63965,1.23633,1.32532,1.32532,0,0,0,.45264,1.03321,6.13354,6.13354,0,0,0,2.32471.83007,15.45487,15.45487,0,0,1,2.5791.74317,3.53162,3.53162,0,0,1,1.502,1.18847,2.89524,2.89524,0,0,1,.48487,1.65723,3.16563,3.16563,0,0,1-.53223,1.752,3.50652,3.50652,0,0,1-1.52979,1.28028,5.34592,5.34592,0,0,1-2.2456.45605,6.69748,6.69748,0,0,1-2.65039-.46094,3.70857,3.70857,0,0,1-1.67676-1.38671A3.93531,3.93531,0,0,1,38.40968,64.35059Z"/>
        <path class="cls-2" d="M50.8672,68.09375V56.44238h1.542V68.09375Z"/>
        <path class="cls-2" d="M64.25392,64.00879l1.542.38965a5.19184,5.19184,0,0,1-1.74462,2.89648,4.80572,4.80572,0,0,1-3.07959.99707,5.53166,5.53166,0,0,1-3.064-.7666,4.78425,4.78425,0,0,1-1.7959-2.22168,7.92221,7.92221,0,0,1-.61621-3.123,6.86855,6.86855,0,0,1,.69531-3.1748,4.76729,4.76729,0,0,1,1.979-2.05859,5.78446,5.78446,0,0,1,2.82568-.70313,4.78033,4.78033,0,0,1,2.94043.88965,4.62387,4.62387,0,0,1,1.66113,2.50391l-1.51806.35742a3.6007,3.6007,0,0,0-1.17627-1.85157,3.13462,3.13462,0,0,0-1.939-.58007,3.7647,3.7647,0,0,0-2.2456.64355,3.39682,3.39682,0,0,0-1.26758,1.72852,6.9618,6.9618,0,0,0-.36523,2.2373,7.10138,7.10138,0,0,0,.4331,2.59473,3.22388,3.22388,0,0,0,1.34717,1.65722,3.7782,3.7782,0,0,0,1.97851.54786,3.31234,3.31234,0,0,0,2.19385-.74707A3.81785,3.81785,0,0,0,64.25392,64.00879Z"/>
        <path class="cls-2" d="M67.22951,68.09375l4.47461-11.65137h1.66113l4.76856,11.65137H76.37746l-1.35938-3.52832H70.1465l-1.27978,3.52832Zm3.36181-4.78418h3.9502L73.32521,60.083q-.5559-1.47071-.82617-2.416a14.87307,14.87307,0,0,1-.62793,2.22461Z"/>
        <path class="cls-2" d="M80.11525,68.09375V56.44238h1.542V66.71875h5.73828v1.375Z"/>
        <path class="cls-2" d="M97.44777,68.09375V57.81738H93.6089v-1.375h9.23535v1.375H98.98976V68.09375Z"/>
        <path class="cls-2" d="M105.28713,68.09375V56.44238h1.542v4.78418h6.05615V56.44238h1.542V68.09375h-1.542V62.60156h-6.05615v5.49219Z"/>
        <path class="cls-2" d="M117.83986,68.09375V56.44238h8.42432v1.375h-6.88282v3.56836h6.4458v1.36719h-6.4458v3.96582h7.15284v1.375Z"/>
        <path class="cls-2" d="M129.502,68.09375V56.44238h5.16553a6.85861,6.85861,0,0,1,2.36865.31446,2.59013,2.59013,0,0,1,1.29541,1.10839,3.30867,3.30867,0,0,1,.48486,1.75684,2.9306,2.9306,0,0,1-.80273,2.08984A4.06378,4.06378,0,0,1,135.5342,62.793a4.0209,4.0209,0,0,1,.92968.58008,7.99237,7.99237,0,0,1,1.2793,1.5498l2.02686,3.1709h-1.93946l-1.5415-2.42383q-.676-1.04883-1.11279-1.60547a3.50132,3.50132,0,0,0-.78272-.77929,2.31572,2.31572,0,0,0-.70361-.30957,4.62908,4.62908,0,0,0-.8584-.05567h-1.78809v5.17383Zm1.5415-6.50879h3.31446a4.97371,4.97371,0,0,0,1.65283-.21875,1.761,1.761,0,0,0,.90625-.69922,1.8914,1.8914,0,0,0,.30957-1.04492,1.735,1.735,0,0,0-.59961-1.35937,2.80625,2.80625,0,0,0-1.89551-.53223h-3.688Z"/>
        <path class="cls-2" d="M140.76662,68.09375l4.47461-11.65137h1.66113l4.76855,11.65137h-1.75634l-1.35938-3.52832h-4.87158l-1.27979,3.52832Zm3.36181-4.78418h3.9502L146.86232,60.083q-.5559-1.47071-.82617-2.416a14.87307,14.87307,0,0,1-.62793,2.22461Z"/>
        <path class="cls-2" d="M153.71681,68.09375V56.44238h4.39453a10.61072,10.61072,0,0,1,1.77247.11133,3.515,3.515,0,0,1,1.43847.54492,2.8562,2.8562,0,0,1,.9336,1.124,3.78918,3.78918,0,0,1-.5918,4.10546,4.53885,4.53885,0,0,1-3.417,1.0293h-2.98877v4.73633Zm1.54151-6.11133h3.0122a3.194,3.194,0,0,0,2.12207-.55664,1.98088,1.98088,0,0,0,.62793-1.56543,2.10783,2.10783,0,0,0-.36914-1.25195,1.73311,1.73311,0,0,0-.97363-.6875,6.61957,6.61957,0,0,0-1.43848-.10352h-2.981Z"/>
        <path class="cls-2" d="M168.669,68.09375V63.1582l-4.49023-6.71582h1.875l2.29687,3.5127q.63575.98583,1.18457,1.97168.52442-.91407,1.27149-2.0586l2.25683-3.42578h1.79688L170.211,63.1582v4.93555Z"/>
        <path class="cls-2" d="M188.57619,66.71875a4.89933,4.89933,0,0,1-1.542,1.18066,4.18789,4.18789,0,0,1-1.80469.39258,3.56447,3.56447,0,0,1-2.83692-1.208,3.28235,3.28235,0,0,1-.85058-2.20117,3.04452,3.04452,0,0,1,.69531-1.95117,5.80149,5.80149,0,0,1,2.07813-1.5293,7.00033,7.00033,0,0,1-1.04883-1.4707,2.57784,2.57784,0,0,1-.26172-1.08887,2.42641,2.42641,0,0,1,.82226-1.82422,2.8988,2.8988,0,0,1,2.07032-.77441,2.69575,2.69575,0,0,1,1.95117.73047,2.35442,2.35442,0,0,1,.75879,1.75684q0,1.66113-2.20117,2.83691l2.08984,2.66309a6.64647,6.64647,0,0,0,.55664-1.6211l1.48633.31738a8.12156,8.12156,0,0,1-1.0332,2.51172,8.31862,8.31862,0,0,0,1.81933,1.78809l-.96094,1.13672A8.31567,8.31567,0,0,1,188.57619,66.71875Zm-.89844-1.12891-2.62305-3.25a5.0287,5.0287,0,0,0-1.56543,1.28321,2.0688,2.0688,0,0,0-.40527,1.17285,2.495,2.495,0,0,0,.56445,1.46972,1.86853,1.86853,0,0,0,1.59766.76271,2.63214,2.63214,0,0,0,1.33105-.40138A3.21578,3.21578,0,0,0,187.67775,65.58984ZM185.667,60.64648a3.79756,3.79756,0,0,0,1.2041-.96093,1.61924,1.61924,0,0,0,.27441-.91407,1.31724,1.31724,0,0,0-.37793-.97363,1.27723,1.27723,0,0,0-.9414-.37793,1.33274,1.33274,0,0,0-.96582.374,1.22461,1.22461,0,0,0-.38574.91406,1.34889,1.34889,0,0,0,.13964.56836,2.94534,2.94534,0,0,0,.417.63183Z"/>
        <path class="cls-2" d="M198.58107,64.35059l1.4541-.127a3.215,3.215,0,0,0,.48047,1.43457,2.628,2.628,0,0,0,1.17285.90528,4.43784,4.43784,0,0,0,1.78809.3457,4.28567,4.28567,0,0,0,1.55762-.26172,2.16762,2.16762,0,0,0,1.00586-.71973,1.66751,1.66751,0,0,0,.3291-.99707,1.51866,1.51866,0,0,0-.31739-.958,2.35687,2.35687,0,0,0-1.0498-.6875,20.93813,20.93813,0,0,0-2.07422-.56836,10.62369,10.62369,0,0,1-2.249-.72657,3.15948,3.15948,0,0,1-1.24317-1.085,2.65341,2.65341,0,0,1-.41015-1.45019,2.96021,2.96021,0,0,1,.501-1.64942,3.09582,3.09582,0,0,1,1.46289-1.16406,5.532,5.532,0,0,1,2.1377-.39746,5.842,5.842,0,0,1,2.28516.417,3.29668,3.29668,0,0,1,1.52148,1.22754,3.52284,3.52284,0,0,1,.57227,1.83594l-1.47852.11133a2.38283,2.38283,0,0,0-.80664-1.669,3.13945,3.13945,0,0,0-2.03027-.56445,3.26032,3.26032,0,0,0-2.03907.51269,1.53982,1.53982,0,0,0-.63964,1.23633,1.32573,1.32573,0,0,0,.45312,1.03321,6.12977,6.12977,0,0,0,2.3252.83007,15.46988,15.46988,0,0,1,2.5791.74317,3.53463,3.53463,0,0,1,1.50195,1.18847,2.895,2.895,0,0,1,.48438,1.65723,3.16958,3.16958,0,0,1-.53223,1.752,3.508,3.508,0,0,1-1.53027,1.28028,5.34371,5.34371,0,0,1-2.24512.45605,6.69748,6.69748,0,0,1-2.65039-.46094,3.706,3.706,0,0,1-1.67676-1.38671A3.92636,3.92636,0,0,1,198.58107,64.35059Z"/>
        <path class="cls-2" d="M210.77541,68.09375V56.44238h4.39453a10.6105,10.6105,0,0,1,1.77246.11133,3.515,3.515,0,0,1,1.43848.54492,2.85618,2.85618,0,0,1,.93359,1.124,3.78918,3.78918,0,0,1-.5918,4.10546,4.53885,4.53885,0,0,1-3.417,1.0293H212.3174v4.73633Zm1.542-6.11133h3.01172a3.19405,3.19405,0,0,0,2.12207-.55664,1.98091,1.98091,0,0,0,.62793-1.56543A2.1079,2.1079,0,0,0,217.71,58.6084a1.7331,1.7331,0,0,0-.97364-.6875,6.61946,6.61946,0,0,0-1.43847-.10352H212.3174Z"/>
        <path class="cls-2" d="M222.70705,68.09375V56.44238h1.542V68.09375Z"/>
        <path class="cls-2" d="M227.76564,68.09375V56.44238h1.58106l6.12011,9.14746V56.44238h1.47754V68.09375h-1.581l-6.12012-9.15527v9.15527Z"/>
        <path class="cls-2" d="M240.38088,68.09375V56.44238h8.4248v1.375h-6.88281v3.56836h6.44531v1.36719h-6.44531v3.96582h7.15332v1.375Z"/>
        <path class="cls-2" d="M265.66994,64.00879l1.541.38965a5.19123,5.19123,0,0,1-1.74414,2.89648,4.80611,4.80611,0,0,1-3.08007.99707,5.53214,5.53214,0,0,1-3.06348-.7666,4.78128,4.78128,0,0,1-1.7959-2.22168,7.91357,7.91357,0,0,1-.61621-3.123,6.86229,6.86229,0,0,1,.69531-3.1748,4.76291,4.76291,0,0,1,1.97949-2.05859,5.78126,5.78126,0,0,1,2.8252-.70313,4.78133,4.78133,0,0,1,2.94043.88965,4.62127,4.62127,0,0,1,1.66113,2.50391l-1.51855.35742a3.59712,3.59712,0,0,0-1.17578-1.85157,3.13417,3.13417,0,0,0-1.93946-.58007,3.76313,3.76313,0,0,0-2.24511.64355,3.39682,3.39682,0,0,0-1.26758,1.72852,6.975,6.975,0,0,0-.36524,2.2373,7.12219,7.12219,0,0,0,.43262,2.59473,3.227,3.227,0,0,0,1.34766,1.65722,3.77943,3.77943,0,0,0,1.97851.54786,3.311,3.311,0,0,0,2.19336-.74707A3.82416,3.82416,0,0,0,265.66994,64.00879Z"/>
        <path class="cls-2" d="M268.64552,68.09375l4.47364-11.65137h1.66113l4.76855,11.65137H277.793l-1.35938-3.52832h-4.87207l-1.2793,3.52832Zm3.36133-4.78418h3.9502L274.74123,60.083q-.55665-1.47071-.82715-2.416a14.815,14.815,0,0,1-.62793,2.22461Z"/>
        <path class="cls-2" d="M281.61818,68.09375V56.44238h5.166a6.85169,6.85169,0,0,1,2.36816.31446,2.58917,2.58917,0,0,1,1.2959,1.10839,3.30825,3.30825,0,0,1,.48437,1.75684,2.93056,2.93056,0,0,1-.80273,2.08984,4.06245,4.06245,0,0,1-2.47949,1.08106,4.01019,4.01019,0,0,1,.92968.58008,7.99507,7.99507,0,0,1,1.28028,1.5498l2.02637,3.1709h-1.93946l-1.542-2.42383q-.6753-1.04883-1.1123-1.60547a3.49153,3.49153,0,0,0-.78321-.77929,2.31844,2.31844,0,0,0-.70312-.30957,4.62908,4.62908,0,0,0-.8584-.05567h-1.78809v5.17383Zm1.542-6.50879h3.31446a4.97891,4.97891,0,0,0,1.65332-.21875,1.75853,1.75853,0,0,0,.90527-.69922,1.88841,1.88841,0,0,0,.31055-1.04492,1.7343,1.7343,0,0,0-.60059-1.35937,2.80278,2.80278,0,0,0-1.89551-.53223h-3.6875Z"/>
        <path class="cls-2" d="M294.19435,68.09375V56.44238h8.42481v1.375h-6.88282v3.56836h6.44532v1.36719h-6.44532v3.96582h7.15332v1.375Z"/>
      </g>
    </g>
  </g>
</Logo>
</a>
</div>
  )
}

export default Streubel


