import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from 'react-bootstrap'
import SalomonLogo from '../../images/salomon-logo'
import HammerNutritionLogo from '../../images/hammer-nutrition-logo'
import SwixLogo from '../../images/swix-logo'
import MadshusLogo from '../../images/madshus-logo'

const Sponsors = () => {
    const data = useStaticQuery(
        graphql`
  query {
    eastIdahoSpine: file(relativePath: { eq: "EISC_logo.jpg" }) {
        childImageSharp {
          fixed (width: 275, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    snakeRiverRoasting: file(relativePath: { eq: "SRRCLogo_black.png" }) {
        childImageSharp {
          fixed (width: 200, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    peakedSports: file(relativePath: { eq: "Peaked-Logo.png" }) {
        childImageSharp {
          fixed (width: 200, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      yostmark: file(relativePath: { eq: "yost-logo.png" }) {
        childImageSharp {
          fixed (width: 200, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      kates: file(relativePath: { eq: "Kates-real-food-logo.png" }) {
        childImageSharp {
          fixed (width: 200, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      toko: file(relativePath: { eq: "Toko-logo.png" }) {
        childImageSharp {
          fixed (width: 197, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
  }
`
    )
    return (
    <Container>
        <Row className="my-4">
            <Col>
            <h3 className="title text-center">
            Sponsored By
        </h3>
            </Col>
        </Row>
        
        <Row className="justify-content-sm-center align-items-center">
        <Col className="text-center my-4" sm={4}>
        <a href="http://eastidspine.com/" target="_blank" rel="noopener noreferrer">
            <Img
                fixed={data.eastIdahoSpine.childImageSharp.fixed}
                alt="Eastern Idaho Spine, Sports, and Rehab Center"
            />
            </a>
        </Col>
        <Col className="text-center my-4" sm={4}>
            <a href="http://www.snakeriverroastingco.com/" target="_blank" rel="noopener noreferrer">
            <Img
                fixed={data.snakeRiverRoasting.childImageSharp.fixed}
                alt="Snake River Roasting Company"
            />
            </a>
        </Col>
        <Col className="text-center my-4" sm={4}>
            <SalomonLogo />
        </Col>
        </Row>

        <Row className="justify-content-sm-center align-items-center">
        <Col className="text-center my-4" sm={4}>
        <a href="http://www.peakedsports.com" target="_blank" rel="noopener noreferrer">
            <Img
                fixed={data.peakedSports.childImageSharp.fixed}
                alt="Peaked Sports, Driggs, Idaho"
            />
            </a>
        </Col>
        <Col className="text-center my-4" sm={4}>
            <a href="http://www.yostmark.com/" target="_blank" rel="noopener noreferrer">
            <Img
                fixed={data.yostmark.childImageSharp.fixed}
                alt="Yostmark Mountain Equipment, Driggs, Idaho"
            />
            </a>
        </Col>
        <Col className="text-center my-4" sm={4}>
        <a href="http://www.yostmark.com/" target="_blank" rel="noopener noreferrer">
            <Img
                fixed={data.kates.childImageSharp.fixed}
                alt="Kate's Real Food"
            />
            </a>
        </Col>
        </Row>
        <Row className="justify-content-sm-center align-items-center">
        <Col className="text-center my-4" sm={4}>
            <HammerNutritionLogo />
        </Col>
        <Col className="text-center my-4" sm={4}>
            <a href="http://www.tokous.com/" target="_blank" rel="noopener noreferrer">
            <Img
                fixed={data.toko.childImageSharp.fixed}
                alt="Toko"
            />
            </a>
        </Col>
        <Col className="text-center my-4" sm={4}>
            <SwixLogo />
        </Col>
        </Row>
    </Container>
    )
}

export default Sponsors